import React from 'react';
import './App.css';
const iframe = '<iframe src="https://devari.minhaconta.zoop.com.br" frameBorder="0" width="100%" height="100%" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" ></iframe>';

function createMarkup() {
  return {__html: iframe};
}

function App() {
  
  return (
    <div className="App">
      <div dangerouslySetInnerHTML={ createMarkup() } />
    </div>
  );
}

export default App;
